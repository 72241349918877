export default function getUrl(path: string) {
  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    if (path.startsWith('/cloud/api')) {
      return path
    }
    // return `/api${path}`
    return path
  }

  // 浏览器环境
  if (typeof window !== 'undefined') {
    return path
  }
  return `https://www.dongchedi.com${path}`
}
