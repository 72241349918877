import Cookies from '@arsenal/arsenal/modules/Cookies'

const Tea =
  typeof window === 'undefined'
    ? () => {
      // pass
    }
    : require('byted-tea-sdk').default

// const Tea = (arg1: any, arg2: any) => {}
interface SendTeaEnterEventParams {
  page_id: string
  extra_params?: any
  pre_page_id?: string
  pre_sub_tab?: string
  sub_tab?: string
}

interface SendTeaStayEventParams {
  page_id?: string
  extra_params?: any
  pre_page_id?: string
  pre_sub_tab?: string
  sub_tab?: string
  stay_time?: number
}

interface SendTeaCommEventParams {
  page_id?: string
  group_id?: string | number
  item_id?: string
  enter_from?: string
  sub_tab?: string
  content_type?: string | number
  car_series_id?: string | number
  link_source?: string
  [key: string]: any
}

interface SendTeaAdEventParams {
  page_id?: string // 点击位置所属页面id
  ad_material_id?: string // 广告素材ID
  ad_target_url?: string // 广告落地页URL
  group_id?: string // 文章ID
  log_extra?: string // 服务端统计字段
  content_type?: string // 文章内容类型
  ad_id?: string // 广告ID
  ad_req_id?: string // 广告接口该次请求的ID
  req_id?: string // 请求ID
  rank?: string // 一级列表排序
  pre_page_id?: string // 前一个页面的页面id
  [key: string]: string | number | undefined
}

let stayParams: SendTeaStayEventParams = {}
let stayTimer: any = null
let PAGE_ID = ''
export default function teaInit({ appId = 1839, zt = '' }) {
  // 初始化业务配置
  Tea('init', {
    app_id: appId,
    channel: 'cn',
    autotrack: {
      //对内的无埋点，必须填以下的值
      custom: 'tea', //固定值
    },
  })
  Tea('config', {
    log: process.env.NODE_ENV === 'development',
    app_name: 'auto_web_pc',
    user_type: 13,
    zt,
  })
  Tea('send')
}

export function teaConfig(params: any) {
  Tea('config', params)
}

// 切换页面时先报stay，再上报进入页面的enter
export function sendTeaEnterEvent(params: SendTeaEnterEventParams) {
  if (!params.page_id) {
    console.error('sendTeaEnterEvent has no page_id')
    // throw new Error('sendTeaEnterEvent has no page_id')
  }
  if (!params.extra_params) {
    params.extra_params = ''
  }
  // pre_page_id必须, pre_sub_tab不必须
  params.pre_page_id = params.pre_page_id || ''
  if (sessionStorage.getItem('pre_page_id') && !params.pre_page_id) {
    params.pre_page_id = sessionStorage.getItem('pre_page_id') || ''
  }
  if (sessionStorage.getItem('pre_sub_tab') && !params.pre_sub_tab) {
    params.pre_sub_tab = sessionStorage.getItem('pre_sub_tab') || ''
  }
  // 记录当前页信息，其他事件使用
  sessionStorage.setItem('page_id', params.page_id)
  sessionStorage.setItem('sub_tab', params.sub_tab || '')
  Tea('page_enter', params)
  // 记录当前页信息
  stayTimer = new Date()
  stayParams = params

  PAGE_ID = params.page_id
}

// 同一个html内部切换用
export const sendTeaStayEvent = (params: SendTeaStayEventParams = {}) => {
  if (params) {
    stayParams = Object.assign({}, params)
  }
  stayParams.stay_time = stayTimer
    ? new Date().getTime() - stayTimer.getTime()
    : -1
  if (!stayParams.page_id) {
    stayParams.page_id = PAGE_ID
  }
  if (!stayParams.extra_params) {
    stayParams.extra_params = ''
  }
  // 记录当前页信息，下页pre使用
  sessionStorage.setItem('pre_page_id', stayParams.page_id || '')
  sessionStorage.setItem('pre_sub_tab', stayParams.sub_tab || '')
  Tea('leave_page', stayParams)
}

export const sendTeaCommEvent = (
  event: string,
  _params: SendTeaCommEventParams = {}
) => {
  const params = Object.assign(
    {
      page_id: PAGE_ID,
    },
    _params
  )
  if (event === 'go_detail') {
    sessionStorage.setItem('detail_group_id', `${params.group_id}` || '')
    sessionStorage.setItem('detail_item_id', params.item_id || '')
    sessionStorage.setItem('detail_enter_from', params.enter_from || '')
  }
  Tea(event, params)
}

export const sendTeaAdEvent = (
  event: string,
  _params: SendTeaAdEventParams = {}
) => {
  const params = Object.assign(
    {
      page_id: PAGE_ID,
      pre_page_id: sessionStorage.getItem('pre_page_id') || '',
    },
    _params
  )
  Tea(event, params)
}

export const sendAdMonitor = (url: string, requestId: string) => {
  const now = new Date().getTime().toString()
  const uniqueId = Cookies('tt_webid') || ''
  new Image().src = `${url.replace(
    /{TS}|__TS__/g,
    now
  )}&mrid=${requestId}&muid=${uniqueId}`

  // mrid
  // muid
}

export const documentVisiblityChange = (
  onShow?: Function,
  onHidden?: Function
) => {
  /*
   ** 页面unload时，统计请求会被cancel
   ** 故离开页面保存stay params, 下一页面上报page stay
   */
  let hidden = ''
  let visibilityChange = ''
  if (typeof document !== 'undefined') {
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden'
      visibilityChange = 'visibilitychange'
      // @ts-ignore
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden'
      visibilityChange = 'msvisibilitychange'
      // @ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
    }
    const onVisibilityChange = () => {
      if ((document as any)[hidden]) {
        stayParams.stay_time = stayTimer
          ? new Date().getTime() - stayTimer.getTime()
          : -1
        sessionStorage.setItem(
          'pre_page_stay_params',
          JSON.stringify(stayParams)
        )
        onHidden && onHidden()
      } else {
        onShow && onShow()
      }
    }
    document.addEventListener(visibilityChange, onVisibilityChange)
  }
}

documentVisiblityChange()
